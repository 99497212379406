import Vue from 'vue'
import Router from 'vue-router'

import term from '@/components/term'
import error from "@/components/error";

Vue.use(Router)

export default new Router({
    mode: "history",
    routes: [
        { path: "/", name: "term", component: term },
        { path: "/error", name: "error", component: error },
    ],
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition;
        } else {
            return { x: 0, y: 0 };
        }
    },
});
