import Vue from 'vue'
import App from "./App.vue";
import VueCryptojs from 'vue-cryptojs'
import 'element-ui/lib/theme-chalk/index.css'
import './plugins/element.js'
import router from './router'

Vue.config.productionTip = false
Vue.use(VueCryptojs)

new Vue({
  router,
  render: h => h(App),
}).$mount("#app");
