<template>
  <div id="app-term">
    <el-container>
      <el-header height="60px">
        <div class="header" :style="{'background-color':headercolor}">
          <img :src="imageurl" />
        </div>
      </el-header>
      <el-main>
        <el-row
          type="flex"
          class="termrow"
          :style="{
            height: termmainheight,
            'padding-bottom': termMainPaddingBottom,
          }"
            v-loading.fullscreen.lock="fullscreenLoading"
            element-loading-background="rgba(0, 0, 0, 0.9)"
        >
          <div class="term">
            <div class="title">
              <p>
                本サービスの提供には売り子ールを使用しています。以下の利用規約に同意いただけた場合のみ、ご利用いただけます。
              </p>
            </div>
            <div class="termIframe">
            <iframe :src="termurl" />
            </div>
          </div>
        </el-row>
        <el-row type="flex" v-if="customtermshow" class="customtermrow">
          <div class="customterm" v-html="customterm"></div>
        </el-row>
        <el-row type="flex" class="cookierow">
          <div class="cookie">
            このwebページおよび売り子ールでは、不正利用防止および利便性向上、各機能を適切に動作させる目的でクッキーを使用します。ご同意いただけない場合は、サービスをご利用いただけません。
          </div>
        </el-row>
      </el-main>
      <el-footer height="50px">
        <div class="footer">
          <div class="agreement" v-on:click="agree" v-on:touchend="agree">
            同意する
          </div>
        </div>
      </el-footer>
    </el-container>
  </div>
</template>

<style>
@import url("https://fonts.googleapis.com/css?family=Noto+Sans+JP:100,300,400,500,700,900&display=swap&subset=japanese");

:root {
  --object-width: 90%;
  --object-text: 77%;
  --margin-top: 5px;
}

body {
  font-family: Noto Sans JP, sans-serif;
  background-color: #f5f5f5 !important;
  overflow: hidden;
  width: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.el-header {
  padding: 0px;
}

.el-main {
  text-align: center;
  height: -webkit-calc(100% - 110px);
  height: calc(100% - 110px);
  position: absolute;
  top: 60px;
  width: 100%;
  padding: 0;
}

.el-footer {
  width: 100%;
  text-align: center;
  position: absolute;
  bottom: 0;
  padding: 0;
}

.termrow {
  height: 100%;
  padding-bottom: 70px;
}

.header {
  position: relative;
  top: 0px;
  background-color: #333; /* #ffffff; */
  height: 60px;
  width: 100%;
  text-align: center;
}

.header img {
  height: 100%;
  max-width: 100%;
  object-fit: contain;
}

.title {
  width: var(--object-width);
  margin: 10px auto;
}

.title p {
  margin-block-start: 0em;
  margin-block-end: 0em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-size: 11px;
  text-align: left;
}

.term {
  padding-top: 3px;
  height: calc(100% - 40px);
  width: 100%;
}

.termIframe {
  width: var(--object-width);
  height: 100%;
  position: relative;
  overflow: auto; 
  -webkit-overflow-scrolling:touch;
  margin: 0 auto;
}

.termIframe iframe{
  position:absolute;
  top:0;
  left:0;
  width:100%;
  height:100%;
  border:none;
  display:block;
  background-color: #ffffff;
}

.customtermrow {
  bottom: 65px;
  position: absolute;
  width: 100%;
  height: 100px;
}

.customterm {
  background-color: #ffffff;
  width: var(--object-width);
  margin: 0 auto;
  height: 100%;
  font-size: 12px;
  overflow-x: auto;
}

.cookierow {
  bottom: 0px;
  position: absolute;
  width: 100%;
  height: 65px;
}

.cookie {
  margin: 5px auto;
  width: var(--object-text);
  padding: 10px calc((var(--object-width) - var(--object-text)) / 2);
  background-color: #ffffff;
  text-align: left;
  font-size: 12px;
  overflow-y: auto;
}

.footer {
  background-color: #ffffff;
  width: 100%;
  padding-top: 8px;
  padding-bottom: 8px;
  bottom: 2px;
}

.footer .agreement {
  background-color: #d2242d;
  color: #ffffff;
  width: var(--object-text);
  height: 40px;
  line-height: 38px;
  margin: 0 auto;
  border-radius: 20px;
}

.footer .agreement:active {
  opacity: 0.8;
}
@media screen and (max-width: 720px) {
  .cookie {
    font-size: 9px;
  }
}

@media screen and (max-width: 400px) {
  .cookie {
    font-size: 7px;
  }
}
</style>

<script>
import axios from "axios";

export default {
  name: "App",
  data() {
    return {
      id: "",
      newUserFlg: true,
      version: "",
      lastaccess: "",
      imageurl: "",
      headercolor: "#333",
      termurl: "",
      redirect: "",
      directory: "",
      customterm: "",
      customtermshow: false,
      sid: "",
      appid: "",
      root: "",
      passphrase: "",
      termPass: false,
      termmainheight: "100%",
      termMainPaddingBottom: "70px",
      endpoint: "https://api.terms-agreement.com/api",
      historyUrl: "https://history.bring-it.fun",
      historyUrlStg: "https://stg-uricall-history-dot-uricall-admin.appspot.com",
      fullscreenLoading: true
    };
  },
  created() {
    // URL引数取得
    let params = this.getQueryString();
    this.directory = params["d"];
    this.sid = params["sid"];
    this.appid = params["appid"];
    this.root = params["root"];

    // SIDがない場合エラーとする
    if (this.sid == undefined) {
      location.href = "./error.html"
    }

    axios.get(this.endpoint + "/uid", {withCredentials: true})
      .then((res) => {
        this.id = res.data.id
        this.newUserFlg = res.data.newflg
        axios
          .get(
            this.endpoint +
              "/directory?d=" +
              this.directory +
              "&nocache=" +
              new Date().getTime()
              , {withCredentials: true}
          )
          .then((res) => {
            this.termPass = res.data.termPass 
            this.version = res.data.term.version;
            this.redirect = res.data.directory.redirect;
            // AES Set Key
            this.passphrase = res.data.system.passphrase;

            if(!this.newUserFlg && this.termPass){
              this.agree()
            }else{
              this.fullscreenLoading = false
              this.imageurl = res.data.directory.image;
              this.headercolor = res.data.directory.color != undefined ? res.data.directory.color == '' ? this.headercolor : '#' + res.data.directory.color : this.headercolor
              this.termurl = res.data.term.url;
              this.customterm = res.data.term.custom;
              this.customtermshow = this.customterm != "";

              if (window.innerWidth <= 600) {
                if (this.customtermshow) {
                  this.termMainPaddingBottom = "190px";
                } else {
                  this.termMainPaddingBottom = "85px";
                }
              } else if (this.customtermshow) {
                this.termMainPaddingBottom = "175px";
              }
            }
          })
          .catch(function(error) {
            console.log(error);
            location.href = "./error.html"
          });
      })
      .catch(function(error) {
        console.log(error);
        location.href = "./error.html"
      });
  },
  methods: {
    window: (onload = function() {
      // 最初に、ビューポートの高さを取得し、0.01を掛けて1%の値を算出して、vh単位の値を取得
      let vh = window.innerHeight * 0.01;
      // カスタム変数--vhの値をドキュメントのルートに設定
      document.documentElement.style.setProperty("--vh", `${vh}px`);
    }),
    handleResize: function() {
      let vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty("--vh", `${vh}px`);
    },
    agree: function() {
      this.lastaccess = new Date().getTime();

      axios.get(this.endpoint + "/cookies?"
                              + "d=" + this.directory 
                              + "&userid=" + this.id
                              + "&termVersion=" + this.version
                              + "&lastAccess=" + this.lastaccess,
        {withCredentials: true}
      )
      .then(res => {
        let encryptedId = this.CryptoJS.AES.encrypt(this.sid + "," + this.id + "," + this.lastaccess + (this.appid != undefined ? "," + this.appid : ""), this.passphrase).toString()
        if(encryptedId.substring(encryptedId.length-1,encryptedId.length) == "="){
          encryptedId = encryptedId.substring(0,encryptedId.length-1)
        } 

        // rootにorderhistoryが指定されている場合、統合履歴画面へ/stadiumID(redirectの最後のディレクトリ)/orderhistor?s=uidを指定してredirect
        if(this.root == "orderhistory"){
          let r = this.redirect.split("/")
          let stadiumID = r[r.length-1]
          // ridirect先がStgだったらStg環境へredirect
          if(this.redirect.indexOf("uricall-stg-1") != -1){
            location.href = this.historyUrlStg + "/" + stadiumID + "/" + this.root + "?s=" + this.id
          }else{
            location.href = this.historyUrl + "/" + stadiumID + "/" + this.root + "?s=" + this.id
          }
        }else{
          location.href = this.redirect + "?ucid=" + encryptedId
        }
        console.log(res)
      })
      .catch(function(error) {
        console.log(error)
      });
    },
    getQueryString: function() {
      var result = {};
      if (1 < window.location.search.length) {
        // 最初の1文字 (?記号) を除いた文字列を取得する
        var query = window.location.search.substring(1);

        // クエリの区切り記号 (&) で文字列を配列に分割する
        var parameters = query.split("&");

        for (var i = 0; i < parameters.length; i++) {
          // パラメータ名とパラメータ値に分割する
          var element = parameters[i].split("=");

          var paramName = decodeURIComponent(element[0]);
          var paramValue = decodeURIComponent(element[1]);

          if (result[paramName]) {
            // すでに同じ値が存在.
            if (result[paramName] instanceof Array) {
              // 配列に格納済み.追加.
              result[paramName].push(paramValue);
            } else {
              // 配列に変更.
              var array = [result[paramName], paramValue];
              result[paramName] = array;
            }
          } else {
            // パラメータ名をキーとして連想配列に追加する
            result[paramName] = paramValue;
          }
        }
      }
      return result;
    },
  },
};
</script>
